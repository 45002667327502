
import { Component, Vue } from 'vue-property-decorator'
import { SoilHumidityList, SoilHumidityDetail, InfoDig, GroupList, GroupData } from '../../types/soilHumidity.d'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import { drawPolygon } from '@/utils/formatData'
require('echarts-liquidfill')

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private onlineImg = require('@/assets/icon/soilHumidity/normal.svg')
  private onlineImgCursor = require('@/assets/icon/soilHumidity/normal-big.svg')
  private offImg = require('@/assets/icon/soilHumidity/offline.svg')
  private offImgCursor = require('@/assets/icon/soilHumidity/offline-big.svg')
  private warningImg = require('@/assets/icon/soilHumidity/warning.svg')
  private warningImgCursor = require('@/assets/icon/soilHumidity/warning-big.svg')

  private deviceList: Array<SoilHumidityDetail> = []
  private deviceTapList: Array<SoilHumidityDetail> = []
  private drawerData: SoilHumidityDetail | null = null
  private monitorList: Array<string> = []
  private infoDig: Array<InfoDig> = []
  private deviceListShow = true
  private tabs = '0'

  private groupList: Array<GroupList> = []
  private groupData: GroupList | null = null
  private groupDetail: Array<SoilHumidityDetail> = []

  get projectId () {
    return this.$route.params.projectId as string
  }

  $refs!: {
    statusEcharts: any;
  }

  created () {
    this.getProjectLocation()
  }

  destroyed (): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 获取设备信息
  getDeviceList () {
    this.$axios.get<SoilHumidityList>(this.$apis.soilHumidity.selectSoilWith, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res.devices || []
      this.deviceTapList = res.devicesTap || []
      const data = [{
        name: '在线',
        value: res.onlineAmount
      }, {
        name: '离线',
        value: res.offLineAmount
      }]
      this.drawEchart(data)
      this.drawMarker(this.deviceList, '1')
    })
  }

  // 获取分组信息
  getGroupList () {
    this.$axios.get<{ total: number; list: Array<GroupList> }>(this.$apis.soilHumidity.selectSoilGroupMapList, {
      projectId: this.projectId
    }).then((res) => {
      this.groupList = res.list || []
      this.drawMarker(this.groupList, '2')
    })
  }

  // 在线离线图
  drawEchart (data: Array<{ name: string; value: string }>) {
    const pieEchart: any = echarts.init(this.$refs.statusEcharts)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#1AD1B0', '#D2D2D2'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${data[0].value}` : `${item}  ${data[1].value}`
        }
      },

      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getDeviceList()

      // 获取分组信息
      this.getGroupList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon (deviceStatus: string, runStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? (runStatus === '1' ? this.warningImg : this.onlineImg) : this.offImg)
    } else {
      return (deviceStatus === '1' ? (runStatus === '1' ? this.warningImgCursor : this.onlineImgCursor) : this.offImgCursor)
    }
  }

  // 地图打点
  drawMarker (list: Array<any>, type: string) {
    this.markers = []
    if (list && list.length) {
      // 循坏将设备marker添加到地图
      if (type === '1') {
        list.forEach((item: SoilHumidityDetail) => {
          if (item.longitude && item.latitude) {
            const icon = this.getIcon(item.deviceStatus, item.runStatus, false)
            const marker: any = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: icon
              }),
              offset: new AMap.Pixel(-18, -48),
              position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
            })
            marker.detail = item
            marker.on('click', this.drawerOpen)
            this.map && this.map.add(marker)
            this.markers.push(marker)
          }
        })
      }
      if (type === '2') {
        list.forEach((item: GroupList) => {
          const colors = item.runStatus === '0' ? 'border: 2px solid #439AFF; color: #439AFF;' : 'border: 2px solid #FF4A39; color: #FF4A39;'
          if (item.longitude && item.latitude) {
            const icon = this.getIcon('1', item.runStatus, false)
            const marker: any = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: icon
              }),
              offset: new AMap.Pixel(-18, -48),
              position: new AMap.LngLat(Number(item.longitude), Number(item.latitude)),
              label: {
                direction: 'top',
                content: `<div class="marker-label-content" style="${colors}">
                            ${item.groupDetails.length}
                          </div>`
              }
            })
            marker.detail = item
            marker.on('click', this.drawerOpen)
            this.map && this.map.add(marker)
            this.markers.push(marker)
          }
        })
      }
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    const status = {
      temperature: '',
      humidity: '',
      ec: ''
    }
    if ((this.drawerData === null && this.groupData === null) || (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId) || (this.groupData && this.groupData.groupId !== e.target.detail.groupId)) {
      const info = [
        {
          title: '温度(°C)',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-wendu.svg'),
          status: ''
        },
        {
          title: '湿度(%)',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-shidu.svg'),
          status: ''
        },
        {
          title: 'pH',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-ph.svg'),
          status: ''
        },
        {
          title: 'EC(mS/cm)',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-diandaolv.svg'),
          status: ''
        },
        {
          title: '氮(mg/kg)',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-dan.svg'),
          status: ''
        },
        {
          title: '磷(mg/kg)',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-lin.svg'),
          status: ''
        },
        {
          title: '钾(mg/kg)',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-jia.svg'),
          status: ''
        },
        {
          title: 'ORP(mV)',
          value: '-',
          icon: require('@/assets/img/soilHumidity/icon-orp.svg'),
          status: ''
        }
      ]
      if (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId) {
        this.drawerClose()
      }
      if (this.groupData && this.groupData.groupId !== e.target.detail.groupId) {
        this.drawerClose()
      }
      if (e.target.detail.deviceId) {
        this.drawerData = e.target.detail
        // 点击切换大图标
        const iconClick = this.getIcon(e.target.detail.deviceStatus, e.target.detail.runStatus, true)
        if (e.target.detail.longitude && e.target.detail.latitude && e.target.detail.groupFlag === '0') {
          e.target.setOffset(new AMap.Pixel(-22, -64))
          e.target.setIcon(iconClick)
        }
        // 温度
        info[0].value = e.target.detail.temperature || '--'
        switch (e.target.detail.temperatureStatus) {
          case '0':
            status.temperature = '正常'
            break
          case '1':
            status.temperature = '过热'
            break
          case '2':
            status.temperature = '冰冻'
            break
          default:
            status.temperature = '--'
            break
        }
        info[0].status = status.temperature
        // 湿度
        info[1].value = e.target.detail.humidity || '--'
        switch (e.target.detail.humidityStatus) {
          case '0':
            status.humidity = '正常'
            break
          case '1':
            status.humidity = '过涝'
            break
          case '2':
            status.humidity = '干旱'
            break
          case '3':
            status.humidity = '极度干旱'
            break
          default:
            status.humidity = '--'
            break
        }
        info[1].status = status.humidity
        // ph
        info[2].value = e.target.detail.ph || '--'
        info[2].status = '--'
        // ec
        info[3].value = (e.target.detail.conductivity) || '--'
        switch (e.target.detail.conductivityStatus) {
          case '1':
            status.ec = '正常'
            break
          case '2':
            status.ec = '偏高'
            break
          case '3':
            status.ec = '偏低'
            break
          default:
            status.ec = '--'
            break
        }
        info[3].status = status.ec
        // 氮
        info[4].value = (e.target.detail.nitrogen) || '--'
        info[4].status = this.getStatus(e.target.detail.nitrogenStatus)
        // 磷
        info[5].value = (e.target.detail.phosphorus) || '--'
        info[5].status = this.getStatus(e.target.detail.phosphorusStatus)
        // 钾
        info[6].value = (e.target.detail.potassium) || '--'
        info[6].status = this.getStatus(e.target.detail.potassiumStatus)
        // orp
        info[7].value = (e.target.detail.redox) || '--'
        info[7].status = this.getStatus(e.target.detail.redoxStatus)
        this.loadDetail(e, info)
      }
      if (e.target.detail.groupId) {
        this.getGroupDetail(e.target.detail.groupId)
        this.groupData = e.target.detail
        // 点击切换大图标
        const iconClick = this.getIcon('1', e.target.detail.runStatus, true)
        if (e.target.detail.longitude && e.target.detail.latitude) {
          e.target.setOffset(new AMap.Pixel(-22, -64))
          e.target.setIcon(iconClick)
        }
      }
    }
    this.drawer = true
  }

  getGroupDetail (groupId: string) {
    this.$axios.get(this.$apis.soilHumidity.selectMapDetail, {
      groupId: groupId
    }).then(res => {
      this.groupDetail = res || []
    }).catch(() => {
      this.groupDetail = []
    }).finally(() => {
      this.getCollection(groupId)
    })
  }

  getCollection (groupId: string) {
    this.monitorList = []
    this.$axios.get(this.$apis.soilHumidity.selectSoilWithConfig, {
      groupId: groupId
    }).then(res => {
      if (res && res.types) {
        res.types.forEach((item: any) => {
          this.monitorList.push(item.isSelect)
        })
      }
    }).catch(() => {
      this.monitorList = ['0', '0', '0', '0', '1', '1', '1', '1']
    })
  }

  getStatus (num: string) {
    const arr = ['正常', '偏高', '偏低']
    return num ? arr[Number(num)] : '--'
  }

  loadDetail (e: any, info: Array<InfoDig>) {
    if (e.target.detail.deviceId) {
      this.$axios.get(this.$apis.devices.getSoilConfig, {
        deviceId: e.target.detail.deviceId
      }).then(res => {
        if (res !== null && res.types) {
          res.types.forEach((item: any) => {
            this.monitorList.push(item.isSelect)
          })
        } else {
          this.monitorList = ['0', '0', '0', '0', '1', '1', '1', '1']
        }
        for (let index = info.length - 1; index >= 0; index--) {
          if (this.monitorList[index] === '1') {
            info.splice(index, 1)
          }
        }
        this.infoDig = info
      }).catch(() => {
        this.infoDig = []
      })
    }
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 清空抽屉
      this.iconTab()
      this.drawerData = null
      this.groupData = null
    }
  }

  // 实时监测点击
  leftDrawerClick () {
    this.deviceListShow = !this.deviceListShow
  }

  // 列表被点击
  tableClick (row: SoilHumidityDetail) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude && row.groupFlag === '0') {
        e.target = this.map.getAllOverlays('marker').find((item: any) => {
          return item.detail.deviceId === row.deviceId
        })
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 分组列表点击
  groupClick (row: GroupData) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude) {
        e.target = this.map.getAllOverlays('marker').find((item: any) => {
          return item.detail.groupId === row.groupId
        })
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 替换图标
  iconTab () {
    // 将图标替换
    if (this.map && this.drawerData) {
      const deviceId = this.drawerData.deviceId || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceId === deviceId)
      const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.runStatus, false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.drawerData.longitude && this.drawerData.latitude && this.drawerData.groupFlag === '0') {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
    // 将图标替换
    if (this.map && this.groupData) {
      const groupId = this.groupData.groupId || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.groupId === groupId)
      const icon = this.getIcon('1', this.groupData.runStatus, false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.groupData.longitude && this.groupData.latitude) {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
  }

  // tab切换
  changeTabs (num: string) {
    this.tabs = num
  }
}
